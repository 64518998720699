<template>
	<div>
		<table-component
		v-if="view == 'eliminados'"
		:properties="modelPropertiesFromName('order')"
		:models="deleted_orders"
		model_name="order">
		</table-component>
	</div>
</template>
<script>
export default {
	components: {
		TableComponent: () => import('@/common-vue/components/display/TableComponent')
	},
	computed: {
		deleted_orders() {
			return this.$store.state.order.deleted_models
		}
	}
}
</script>